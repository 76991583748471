// imports
import * as bootstrap from 'bootstrap'
import Headroom from 'headroom.js'
import Rellax from 'rellax'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import Swal from 'sweetalert2'

// bootstrap
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl))

// headroom
const headroomMenu = document.querySelector('.menu-container')
var headroomOptions = {
  offset: {
    up: 64,
    down: 0,
  },
}
const headroom = new Headroom(headroomMenu, headroomOptions)
headroom.init()

// rellax
var rellax = new Rellax('.rellax', {
  breakpoints: [576, 768, 992],
})

// splide
let splideCheck = document.getElementById('hero-splide')
if (splideCheck) {
  const heroSplide = new Splide('.hero-splide', {
    arrows: false,
    autoplay: true,
    cover: true,
    height: '100vh',
    interval: 6000,
    pagination: false,
    pauseOnHover: false,
    rewind: true,
    speed: 4000,
    type: 'fade',
    width: '100vw',
  })
  heroSplide.mount()
} else {
  console.log('The hero Splide is not present.')
}

const footerSplide = new Splide('.footer-splide', {
  arrows: false,
  autoScroll: {
    speed: 0.3,
  },
  autoWidth: true,
  drag: 'free',
  gap: '5rem',
  pagination: false,
  type: 'loop',
})
footerSplide.mount({ AutoScroll })

// engage
const engage = document.getElementById('engage')
engage.addEventListener('submit', function (element) {
  element.preventDefault()
  const formData = new FormData(engage)
  const object = Object.fromEntries(formData)
  const json = JSON.stringify(object)

  Swal.fire({
    icon: 'info',
    title: 'Enviando formulário',
    html: 'Por favor, aguarde...',
    didOpen: () => {
      Swal.showLoading()
    },
  })

  fetch('https://api.web3forms.com/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: json,
  })
    .then(async (response) => {
      let json = await response.json()
      if (response.status == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Sua mensagem foi enviada',
          text: 'Agradecemos demais pelo seu interesse na nossa perspectiva. Faremos uma análise inicial e logo entraremos em contato!',
          confirmButtonText: 'OK',
          confirmButtonColor: '#568e95',
        })
      } else {
        console.log(response)
        Swal.fire({
          icon: 'error',
          title: 'Erro inesperado',
          text: json.message,
          confirmButtonText: 'OK',
          confirmButtonColor: '#568e95',
        })
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro inesperado',
        text: 'Alguma coisa deu errado no envio do formulário. Por favor, tente novamente em alguns minutos.',
        confirmButtonText: 'OK',
        confirmButtonColor: '#568e95',
      })
    })
})

// newsletter
let newsletter = document.getElementById('newsletter')
if (newsletter !== null) {
  newsletter.addEventListener('submit', function (element) {
    element.preventDefault()
    const formData = new FormData(newsletter)
    const object = Object.fromEntries(formData)
    const json = JSON.stringify(object)

    Swal.fire({
      icon: 'info',
      title: 'Enviando formulário',
      html: 'Por favor, aguarde...',
      didOpen: () => {
        Swal.showLoading()
      },
    })

    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: json,
    })
      .then(async (response) => {
        let json = await response.json()
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Agradecemos desde já pelo seu interesse!',
            text: 'O nosso mailing está sendo estruturado neste momento e em breve você começará a receber as novidades do projeto em seu e-mail cadastrado.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#568e95',
          })
        } else {
          console.log(response)
          Swal.fire({
            icon: 'error',
            title: 'Erro inesperado',
            text: json.message,
            confirmButtonText: 'OK',
            confirmButtonColor: '#568e95',
          })
        }
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro inesperado',
          text: 'Alguma coisa deu errado no envio do formulário. Por favor, tente novamente em alguns minutos.',
          confirmButtonText: 'OK',
          confirmButtonColor: '#568e95',
        })
      })
  })
} else {
  console.log('The newsletter form is not present.')
}
